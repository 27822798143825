/*
 * Copyright 2024 New Vector Ltd.
 *
 * SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
 * Please see LICENSE files in the repository root for full details.
 */

.mx_ResetIdentityPanel {
    .mx_ResetIdentityPanel_content {
        display: flex;
        flex-direction: column;
        gap: var(--cpd-space-3x);

        > span {
            font: var(--cpd-font-body-md-medium);
            text-align: center;
        }
    }
}
