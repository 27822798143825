/*
Copyright 2024 New Vector Ltd.
Copyright 2019-2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SetIdServer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-8;

    .mx_Field {
        width: 100%;
        margin: 0;
    }
}

.mx_SetIdServer_tooltip {
    max-width: var(--SettingsTab_tooltip-max-width);
}
